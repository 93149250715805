<nav class="navbar navbar-expand-lg navbar-dark">
  <div class="container-fluid navbar__container">
    <div class="navbar__logo navbar-brand">
      <img
        alt="logo"
        src="./assets/images/logo.png"
        class="navbar__logo__img"
      />
    </div>
    <button
      class="navbar-toggler"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div
      class="collapse navbar-collapse justify-content-end"
      id="navbarSupportedContent"
    >
      <ul class="navbar-nav">
        <li class="nav-item">
          <i
            class="bi bi-house-fill"
            [ngClass]="{ active: activeLink === 'home' }"
          ></i>
          <a
            class="nav-link"
            aria-current="page"
            href="#home"
            [ngClass]="{ active: activeLink === 'home' }"
            (click)="onaLinkClicked('home')"
            >Home</a
          >
        </li>

        <li class="nav-item">
          <i
            class="bi bi-person-fill"
            [ngClass]="{ active: activeLink === 'about' }"
          ></i>
          <a
            [ngClass]="{ active: activeLink === 'about' }"
            class="nav-link"
            aria-current="page"
            href="#about"
            (click)="onaLinkClicked('about')"
            >About</a
          >
        </li>

        <li class="nav-item">
          <i
            class="bi bi-stars"
            [ngClass]="{ active: activeLink === 'portfolio' }"
          ></i>
          <a
            [ngClass]="{ active: activeLink === 'portfolio' }"
            class="nav-link"
            aria-current="page"
            href="#portfolio"
            (click)="onaLinkClicked('portfolio')"
            >Portfolio</a
          >
        </li>

        <li class="nav-item">
          <i
            class="bi bi-award-fill"
            [ngClass]="{ active: activeLink === 'certificate' }"
          ></i>
          <a
            [ngClass]="{ active: activeLink === 'certificate' }"
            class="nav-link"
            aria-current="page"
            href="#certificate"
            (click)="onaLinkClicked('certificate')"
            >Certificates</a
          >
        </li>

        <li class="nav-item">
          <i
            class="bi bi-envelope-fill"
            [ngClass]="{ active: activeLink === 'contact' }"
          ></i>
          <a
            [ngClass]="{ active: activeLink === 'contact' }"
            class="nav-link"
            aria-current="page"
            href="#footer"
            (click)="onaLinkClicked('contact')"
            >Contact</a
          >
        </li>
      </ul>
    </div>
  </div>
</nav>
