<div class="card">
  <div class="card-border">
    <img
      [src]="project.imageScr"
      class="card-img-top"
      alt="project"
      [ngClass]="{ animation: project.animation }"
    />
  </div>
  <div class="card-bottom">
    <div class="card-bottom__title">
      <h6>{{ project.title }}</h6>
      <i class="bi bi-caret-up-fill"></i>
    </div>

    <div class="card-bottom__info">
      <h5 class="card-bottom__info__title">{{ project.headTitle }}</h5>
      <p class="card-bottom__info__description">{{ project.description }}</p>
      <div class="card-bottom__info__languages">
        <i class="bi bi-pencil-square"></i>
        <span *ngFor="let language of project.languages; let i = index"
          >{{ language }}
          <span *ngIf="i < project.languages.length - 1">,</span>
        </span>
      </div>
      <div class="card-bottom__info__buttons">
        <button type="button" class="btn btn-primary special-btn">
          <i class="bi bi-github"></i>
          <a [href]="project.codeURL" target="_blank"> Code </a>
        </button>
        <button type="button" class="btn btn-primary special-btn">
          <i class="bi bi-link-45deg"></i>
          <a [href]="project.websiteURL" target="_blank"> View </a>
        </button>
      </div>
    </div>
  </div>
</div>
