<div class="card">
  <img [src]="certificate.logoScr" class="card-img-top" alt="logo" />
  <div class="card__header">
    <h5 class="card__header__title">{{ certificate.name | titlecase }} </h5>
    <span>{{ certificate.organization | titlecase}}  </span>
  </div>
  <div class="card__buttons">
    <button type="button" class="btn btn-primary">
      <i class="bi bi-award-fill"></i>
      <a [href]="certificate.certificateURL">Certificate</a>
    </button>

    <button type="button" class="btn btn-primary">
      <i class="bi bi-github"></i>
      <a [href]="certificate.FinalProjectURL">Final Project</a>
    </button>
  </div>
</div>
