<div class="about">
  <h4 class="about__title" data-aos="fade-in" data-aos-duration="2000">
    <i class="bi bi-person-fill"></i>
    <span>About Me</span>
  </h4>
  <div class="about__body">
    <div class="about__body__info">
      <p data-aos="fade-up" data-aos-duration="2000">
        A front-end engineer, studied computer science then I found my passion
        for working on anything web related especially the frontend later.
        Currently working as a frontend web engineer where my passion for
        development and design converge. Being a diligent, hardworking, and
        result-oriented lady, I always work towards achieving the best results
        on each project I lay my hands on. I develop websites using cutting-edge
        technologies:
      </p>
      <div
        class="about__body__info__skills"
        data-aos="fade-down"
        data-aos-duration="2000"
      >
        <img src="./assets/icons/html.svg" width="64px" height="64px" />
        <img src="./assets/icons/css3.svg" width="64px" height="64px" />
        <img src="./assets/icons/sass.svg" width="64px" height="64px" />
        <img src="./assets/icons/js.svg" width="64px" height="64px" />
        <img src="./assets/icons/npm.png" width="64px" height="64px" />
        <img src="./assets/icons/angular.svg" width="64px" height="64px" />
        <img src="./assets/icons/node.png" width="64px" height="64px" />
        <img src="./assets/icons/git.svg" width="64px" height="64px" />
      </div>
    </div>
    <div class="about__body__img" data-aos="fade-in" data-aos-duration="2000">
      <img
        src="./assets//images/profile.PNG"
        class="about__body__img__profile"
        height="350px"
        width="325px"
      />
    </div>
  </div>
</div>
