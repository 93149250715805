<div class="home-container">
  <div class="home">
    <div class="home__text-wrapper" data-aos="fade-up" data-aos-duration="2500">
      <div class="home__text-wrapper__welcome">
        <span>HI THERE</span>
        <span class="home__text-wrapper__welcome__wave"> 👋 </span>

        <span class="iam">I'M</span>
      </div>
      <div class="home__text-wrapper__info">
        <span class="home__text-wrapper__info__name">Amal Ahmed</span>
        <span class="home__text-wrapper__info__title"
          >Senior Frontend Engineer</span
        >
        <p class="home__text-wrapper__info__about">
          I’m a passionate frontend engineer dedicated to combining the art of
          design with the art of programming.
        </p>
      </div>
    </div>
    <div
      class="home__image-wrapper"
      data-aos="fade-left"
      data-aos-duration="2500"
    >
      <img
        class="home__image-wrapper__img"
        src="./assets/images/hero-image.png"
        width="370px"
        height="400"
      />
    </div>
  </div>
  <div class="buttons">
    <button type="button" class="btn btn-primary">
      <i class="bi bi-file-earmark-person-fill"></i>
      <a
        href="https://drive.google.com/file/d/1ma8xPooVBKjJHbVcNY1YFjVAXqKva76W/view?usp=drive_link"
        target="_blank"
        >Resume</a
      >
    </button>
    <button type="button" class="btn btn-primary">
      <i class="bi bi-github"></i>
      <a href="https://github.com/AmalAhmedibrahim" target="_blank">Github</a>
    </button>
    <button type="button" class="btn btn-primary">
      <i class="bi bi-linkedin"></i>
      <a href="https://www.linkedin.com/in/amal-fathelbab/" target="_blank">
        Linkedin</a
      >
    </button>
    <button type="button" class="btn btn-primary special-btn">
      <i class="bi bi-envelope-fill"></i>
      <a href="#footer">Work Together</a>
    </button>
  </div>
</div>
