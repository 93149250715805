<div class="certificates">
  <h4 class="certificates__header" data-aos="fade-in" data-aos-duration="2000">
    <img
      src="./assets/images/certificate.png"
      class="certificates__header__img"
    />
    <span>Certificates</span>
  </h4>
  <div class="certificates__cards">
    <app-certificate
      *ngFor="let certificate of certificates"
      [certificate]="certificate"
      class="certificates__cards__card"
      data-aos="zoom-in"
      data-aos-duration="2000"
    ></app-certificate>
  </div>
</div>
