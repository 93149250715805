<app-loader *ngIf="isLoading"></app-loader>
<app-header [activeLink]="activeSection"> </app-header>
<particles
  [params]="myParams"
  [style]="myStyle"
  [width]="width"
  [height]="height"
></particles>
<div class="wrapper">
  <div class="section" id="home">
    <app-home></app-home>
  </div>
  <div id="about">
    <app-about></app-about>
  </div>
  <div class="section" id="portfolio">
    <app-portfolio></app-portfolio>
  </div>
  <div id="certificate">
    <app-certificates></app-certificates>
  </div>

  <div class="footer" id="footer">
    <app-footer> </app-footer>
  </div>
</div>
