<footer>
  <div class="footer-container">
    <div class="footer">
      <div
        class="footer__header"
        data-aos="fade-right"
        data-aos-duration="2000"
      >
        <h4 class="footer__header__title">
          <i class="bi bi-envelope-fill"></i>
          <span>Let’s connect</span>
        </h4>
        <p class="footer__header__description">
          Have cool idea for new project? want to build a website? you have a
          question don’t hesitate to contact me.
        </p>
      </div>

      <ul class="footer__contact" data-aos="fade-up" data-aos-duration="2000">
        <li class="footer__contact__item">
          <i class="bi bi-geo-alt-fill"></i>
          <span>Cairo,Egypt</span>
        </li>
        <li class="footer__contact__item">
          <i class="bi bi-envelope-fill"></i>
          <span>dev.amal.ahmed@gmail.com</span>
        </li>
        <li class="footer__contact__item">
          <i class="bi bi-telephone-fill"></i>
          <span>+201099949445</span>
        </li>
      </ul>
    </div>
    <div class="form" data-aos="fade-in" data-aos-duration="3000">
      <form
        action="https://formsubmit.io/send/dev.amal.ahmed@gmail.com"
        method="POST"
      >
        <div class="form-group">
          <input
            type="text"
            name="name"
            class="form-control"
            id="name"
            aria-describedby="emailHelp"
            placeholder="Name"
          />
          <input
            type="email"
            name="email"
            class="form-control"
            id="email"
            aria-describedby="emailHelp"
            placeholder="Email"
          />
          <textarea
            class="form-control"
            id="message"
            name="message"
            rows="4"
            placeholder="Enter Your Message...."
          ></textarea>
        </div>
        <div class="button">
          <app-round-button title="Send"></app-round-button>
        </div>
      </form>
    </div>
  </div>
  <div class="social-media">
    <span> Designed & Developed by Me 👩‍💻</span>
    <ul class="social-media__list">
      <li class="social-media__list__item">
        <a href="https://www.instagram.com/dev.amal.ahmed/" target="_blank">
          <i class="bi bi-instagram"></i>
        </a>
      </li>
      <li class="social-media__list__item">
        <a href="https://www.behance.net/Amalfathelbab" target="_blank">
          <i class="bi bi-behance"></i>
        </a>
      </li>
      <li class="social-media__list__item">
        <a href="https://twitter.com/devamalahmed" target="_blank">
          <i class="bi bi-twitter"></i>
        </a>
      </li>
      <li class="social-media__list__item">
        <a href="https://github.com/AmalAhmedibrahim" target="_blank">
          <i class="bi bi-github"></i>
        </a>
      </li>
      <li class="social-media__list__item">
        <a href="https://www.linkedin.com/in/amal-fathelbab/" target="_blank">
          <i class="bi bi-linkedin"></i>
        </a>
      </li>
    </ul>
  </div>
</footer>
